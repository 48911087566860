// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-homepage-index-jsx": () => import("./../../../src/homepage/index.jsx" /* webpackChunkName: "component---src-homepage-index-jsx" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-categories-list-template-js": () => import("./../../../src/templates/categories-list-template.js" /* webpackChunkName: "component---src-templates-categories-list-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-course-template-js": () => import("./../../../src/templates/course-template.js" /* webpackChunkName: "component---src-templates-course-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-not-found-template-js": () => import("./../../../src/templates/not-found-template.js" /* webpackChunkName: "component---src-templates-not-found-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-tags-list-template-js": () => import("./../../../src/templates/tags-list-template.js" /* webpackChunkName: "component---src-templates-tags-list-template-js" */)
}

